<template>
    <div >
        <div class="preview-box1">
            <div class="uploader-container">
                <van-uploader
                    :preview-image="true"
                    :after-read="afterRead"
                    @delete="del"
                    v-model="fileList"
                    deletable
                    accept="image/*">
                </van-uploader>
            </div>
            <div class="tips">
                仅限上传图片
            </div>
        </div>
    </div>
</template>

<script>
    // import { Notify } from 'vant';
    // import icon from '../assets/images/icon-upload-doc.png';
    import { uploadByPieces } from '@/utils/upload'; //引入uploadByPieces方法
    export default {
        data() {
            return{
                show: false,
                fileList: [],
                progress: 0,
                up: false,
            }
        },
        methods:{
            afterRead(val){
                // 标志上传中
                val.status = 'uploading';
                val.message = '上传中...';
                var file = val.file;
                this.fileMName = file.name;
                var _pindex = file.name.lastIndexOf('.');
                file.filename = Date.now() + file.name.slice(_pindex);
                uploadByPieces({
                  url: '/erp/file/upload',
                  file: file, // 视频实体
                  pieceSize: 3, // 分片大小
                  success: (data) => {
                    this.progress = 100;
                    this.up = false;
                    console.log(data);
                    val.status = 'sucess';
                    val.message = '';
                    file.url = data.file_path; 
                    //this.fileChange(val, this.fileList);
                    this.$emit('fileChange', val, this.fileList);
                  },
                  error: (e) => {
                    console.error("上传失败！", e);
                    val.status = 'failed';
                    val.message = '上传失败';
                    this.$emit('fileChange', val, this.fileList);
                    //this.fileChange();
                  },
                  uploading: (chunk, allChunk) => {
                    let st = Math.floor((chunk / allChunk) * 100);
                    this.progress = st;
                  },
                });
                return false;
            },
            del(file, filelist){
                this.$emit('fileChange', file, filelist);
            },
            clear(){
                this.fileList = [];
                this.progress = 0;
                this.up = false;
            },
        }
    }
</script>

<style scoped>
    .uploader-container{
        font-size: 14px;
        position: relative;
        /* padding-top:10px ; */
        text-align: left;
        padding: 10px 10px;
    }
    .uploader-container .button-box{
        /* position: absolute;
        right: 20px;
        top: 0;
        width: 120px; */
    }
    .preview-box1{
        min-height: 160px;
        position: relative;
    }
    .file-item{
        position: relative;
        display: flex;
        width: 90%;
        margin: 5px 20px;
        padding-bottom: 5px;
        justify-content: space-between;
        font-size: 16px;
    }
    .item-progress{
        width: 100%;
        position: absolute;
        bottom: 0;
        right: 0;
    }
    .tips{
        font-size: 14px;
        color:#666;
        text-align: left;
        padding: 5px 20px;
    }
</style>