<template>
    <van-popup v-model="show" position="top" @close="close">
        <div class="preview-box1">
            <div class="list-item">
                <van-radio-group v-model="reason">
                    <van-radio 
                        class="my-radio"
                        v-for="(item, index) in list" :key="item.id"
                        :name="index" 
                        >{{item.name}}
                    </van-radio>
                </van-radio-group>
            </div>
            <div class="list-item" v-if="reason == this.list.length - 1">
                <van-field
                  class="textarea"
                  v-model="other"
                  rows="2"
                  autosize
                  type="textarea"
                  placeholder="请输入其它原因"
                />
                
            </div>
            <!-- <div class="list-item">
                <upload-image-item @fileChange="fileChange"></upload-image-item>
            </div> -->
        </div>
        <van-divider />
        <van-button type="info" style="margin: 10px;" @click="onConfirm">确&nbsp;&nbsp;&nbsp;&nbsp;认</van-button>
    </van-popup>
</template>

<script>
    import { Notify } from 'vant';
    // import UploadImageItem from '@/components/mes/UploadImageItem.vue';
    export default {
        props: ['list'],
        data() {
            return{
                show: false,
                up: false,
                reason: -1,
                other: '',
            }
        },
        methods:{
            onConfirm(){
                console.log(this.reason);
                if(this.reason == -1){
                   return  Notify({ type: 'warning', message: '请选择原因' });
                }
                var index = Number(this.reason);
                // 选择了其它原因
                if(index == this.list.length - 1 && !this.other){
                    return  Notify({ type: 'warning', message: '请明确什么原因' });
                }
                var text = index == this.list.length - 1 ? this.other :this.list[index].name;
                // var imgs = this.getVilidFiles();
                this.$parent.confirmPause(text);
                this.show = false;
            },
            close(){
                this.reason = -1;
                this.other = '';
            },
            fileChange(file, list){
                console.log('file', list);
                this.fileList = list;
            },
            // 获取有效的文件
            getVilidFiles(){
                //var fileStr = '';
                var list = this.fileList.filter((item)=>{
                    return item.status == 'sucess';
                });
                if(list.length == 0){
                    return '';
                }
                return list.map((item)=> item.file.url).join(',');
            },
        }
    }
</script>

<style scoped>
    .preview-box1{
        min-height: 160px;
        position: relative;
        /* display: flex;
        justify-content: left;
        margin: 0 auto;
        width: 240px; */
        font-size: 15px;
    }
    .list-item{
        margin: 10px 20px;
    }
    .list-item .textarea{
        background-color: #f2f2f2;
        padding: 5px;
    }
    .my-radio{
        padding: 5px 0;
    }
</style>