import { render, staticRenderFns } from "./UploadImageItem.vue?vue&type=template&id=13ddafc1&scoped=true&"
import script from "./UploadImageItem.vue?vue&type=script&lang=js&"
export * from "./UploadImageItem.vue?vue&type=script&lang=js&"
import style0 from "./UploadImageItem.vue?vue&type=style&index=0&id=13ddafc1&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13ddafc1",
  null
  
)

export default component.exports