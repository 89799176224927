<template>
    <van-popup v-model="show" position="top" @close="close">
        <div class="preview-box1">
            <div class="list-item">
                <van-radio-group v-model="result" direction="horizontal">
                  <van-radio name="1">合格</van-radio>
                  <van-radio name="-1">不合格</van-radio>
                </van-radio-group>
            </div>
                
            <div class="list-item" v-show="result==-1">
                <van-field
                  class="textarea"
                  v-model="remark"
                  rows="3"
                  autosize
                  type="textarea"
                  placeholder="不合格说明"
                />
            </div>
            <div class="list-item" v-show="result==-1">
                <upload-image-item @fileChange="fileChange" ref="uploader"></upload-image-item>
            </div>
        </div>
        
        <van-divider />
        <van-button 
            type="info" style="margin: 10px;" @click="onConfirm">确&nbsp;&nbsp;&nbsp;&nbsp;认          </van-button>
    </van-popup>
</template>

<script>
    import { Notify } from 'vant';
    import UploadImageItem from '@/components/mes/UploadImageItem.vue';
    // import { toast } from '@/utils/common.js';
    export default {
        components:{
           UploadImageItem 
        },
        data() {
            return{
                show: false,
                fileList: [],
                remark: '',
                result: 0,
            }
        },
        methods:{
            onConfirm(){
                console.log(this.fileList);
                
                if(this.result == 0){
                    return Notify({ type: 'warning', message: '请选择检验结果' });
                }
                var data = {
                    result: this.result,
                    remark: '',
                    imgs: '',
                    'video': '',
                };
                if(this.result == -1){
                    var files = this.getVilidFiles();
                    if(!files){
                        return Notify(
                        { type: 'warning', message: '选择不合格，需要上传相应的图片。' }
                        );
                    }
                    data['remark'] = this.remark;
                    data['imgs'] = files;
                }
                // 提交
                this.$parent.onConfirmFinish(data);
                this.show = false;
            },
            fileChange(file, list){
                console.log('file', list);
                this.fileList = list;
            },
            close(){
                this.fileList = [];
                this.remark = '';
                this.result = 0;
                this.$refs.uploader.clear();
            },
            // 获取有效的文件
            getVilidFiles(){
                //var fileStr = '';
                var list = this.fileList.filter((item)=>{
                    return item.status == 'sucess';
                });
                if(list.length == 0){
                    return '';
                }
                return list.map((item)=> item.file.url).join(',');
            },
        }
    }
</script>

<style scoped>
    .uploader-container{
        font-size: 14px;
        position: relative;
        /* padding-top:10px ; */
        text-align: left;
        padding: 10px 10px;
    }
    .uploader-container .button-box{
        /* position: absolute;
        right: 20px;
        top: 0;
        width: 120px; */
    }
    .preview-box1{
        min-height: 160px;
        position: relative;
    }
    .list-item{
        margin: 10px 20px;
    }
    .list-item .textarea{
        background-color: #f2f2f2;
        padding: 5px;
    }
</style>