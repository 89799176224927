<template>
    <van-popup v-model="show" position="top" @close="close">
        <div class="preview-box1">
            <div class="list-item">
                <van-field
                  class="textarea"
                  v-model="remark"
                  rows="2"
                  autosize
                  type="textarea"
                  placeholder="请描述异常"
                />
                
            </div>
            <div class="list-item">
                <upload-image-item @fileChange="fileChange" ref="uploader"></upload-image-item>
            </div>
        </div>
        <van-divider />
        <van-button type="info" style="margin: 10px;" @click="onConfirm">确&nbsp;&nbsp;&nbsp;&nbsp;认</van-button>
    </van-popup>
</template>

<script>
    import { Notify } from 'vant';
    import UploadImageItem from '@/components/mes/UploadImageItem.vue';
    export default {
        components:{
           UploadImageItem 
        },
        data() {
            return{
                show: false,
                remark: '',
                fileList: [],
            }
        },
        methods:{
            onConfirm(){
                console.log(this.remark);
                if(!this.remark){
                   return  Notify({ type: 'warning', message: '异常描述不能为空' });
                }
                var imgs = this.getVilidFiles();
                if(!imgs){
                   return  Notify({ type: 'warning', message: '异常的图片不能为空' });
                }
                this.$parent.confirmCallException(this.remark, imgs);
                this.show = false;
            },
            close(){
                this.remark = '';
                this.fileList = [];
                this.$refs.uploader.clear();
            },
            fileChange(file, list){
                console.log('file', list);
                this.fileList = list;
            },
            // 获取有效的文件
            getVilidFiles(){
                //var fileStr = '';
                var list = this.fileList.filter((item)=>{
                    return item.status == 'sucess';
                });
                if(list.length == 0){
                    return '';
                }
                return list.map((item)=> item.file.url).join(',');
            },
        }
    }
</script>

<style scoped>
    .preview-box1{
        min-height: 160px;
        position: relative;
        /* display: flex;
        justify-content: left;
        margin: 0 auto;
        width: 240px; */
    }
    .list-item{
        margin: 10px 20px;
    }
    .list-item .textarea{
        background-color: #f2f2f2;
        padding: 5px;
    }
    .my-radio{
        padding: 5px 0;
    }
</style>