<template>
    <div class="preview-box1">
        <div class="my-file-list" >
            <div class="file-box" 
                :class="getItemClassName(file.status)" 
                v-for="(file, index) in dataList" :key="index" @click="play(file)">
              <div class="file-inner">
                <van-image :src="file.url" />
                <div class="text">{{file.name}}</div>  
              </div>
              <van-icon name="clear" class="file-del" @click.stop="delFile(index)" />
              <div class="loading">{{file.message}}</div>
              <div class="fail-box">
                  <!-- <van-icon name="fail" color="red" /> -->
                  <van-icon name="warning" color="#e04c53" size="26" />
                  <div class="fail-text">{{file.message}}</div>
              </div>
            </div>
        </div>
        <div class="uploader-container">
            <van-uploader
                ::max-size="maxSize"
                :preview-image="false"
                :before-read="beforeRead"
                :after-read="afterRead"
                v-model="fileList"
                deletable
                accept="video/*">
                <van-button icon="plus" type="info">上传文件</van-button>
            </van-uploader>
        </div>
        <div class="tips">
            仅限上传视频文件, 大小不能超过200M
        </div>
    </div>
</template>

<script>
    import { Dialog, Notify } from 'vant';
    import icon from '@/assets/images/demo-video.png';
    import { uploadByPieces } from '@/utils/upload'; //引入uploadByPieces方法
    // import { toast } from '@/utils/common.js';
    var cls =  require('@/assets/images/demo-video.png');
    //var word =  require('@/assets/images/file-word.png');
    // var excel =  require('@/assets/images/file-excel.png');
    // var ppt =  require('@/assets/images/file-ppt.png');
    var pieceSize = 8;
    export default {
        name: 'UploadVideoItem',
        data() {
            return{
                fileList: [],
                // _tempList: [],
                icon: icon,
                progress: 0,
                fileMName: '',
                up: false,
                src: '',
                dataList: [],
                maxSize: 200 * 1024 * 1024,
            }
        },
        methods:{
            onConfirm(){
                console.log(this.fileList);
                var data = this.getFiles();
                console.log('file', data);
                if(!data){
                    return Notify({ type: 'warning', message: '上传文件不能为空' });
                }
                this.$parent.uploadFile(data);
                this.fileList = [];
            },
            getFiles(){
                var ulrs = [],names = [];
                for (var i = 0; i < this.fileList.length; i++) {
                    var item = this.fileList[i];
                    if(item.status == 'sucess'){
                        ulrs.push(item.file.url);
                        names.push(item.file.filename);
                    }
                }
                if(ulrs.length){
                    return {
                        file_url: ulrs.join(','),
                        file_name: names.join(','),
                    }
                }
                return null;
            },
            beforeRead(file){
                // 是否要校验文件类型
                console.log('file', file);
                if(file.size > this.maxSize){
                    Notify({ type: 'warning', message: '文件太大了' });
                    return false;
                }
                return true;
            },
            delFile(index){
                // 如果是上传成功的
                var file = this.dataList[index];
                if(file.status == -1){
                   return  this.dataList.splice(index, 1);
                }
                if(file.status == 0){
                   return ;
                }
                Dialog.confirm({
                    message: '确认要删除吗？',
                }).then(()=> {
                    this.dataList.splice(index, 1);
                    this.fileList.splice(index, 1);
                    this.$emit('fileChange', file, this.fileList);
                })
            },
            afterRead(val){
                // 标志上传中
                val.status = 'uploading';
                console.log('val', val);
                var file = val.file;
                this.fileMName = file.name;
                var _file = {
                    name: file.name,
                    status: 0, //1 成功 -1 失败
                    message: '开始上传',
                    url: cls,
                }
                
                this.dataList.push(_file);
                var _pindex = file.name.lastIndexOf('.');
                file.filename = Date.now() + file.name.slice(_pindex);
                uploadByPieces({
                  url: '/erp/file/upload',
                  file: file, // 视频实体
                  pieceSize: pieceSize, // 分片大小
                  readed:(url) => {
                      this.src = url.split(',')[1];
                      //console.log(url);
                  },
                  success: (data) => {
                    this.progress = 100;
                    this.up = false;
                    _file.status = 1;
                    _file.message = '';
                    _file.path = data.file_path;
                    console.log(data);
                    val.status = 'sucess';
                    val.message = '';
                    val.path = data.file_path;
                    this.src = data.file_path;
                    this.$emit('fileChange', _file, this.fileList);
                  },
                  error: (e) => {
                    console.error("上传失败！", e);
                    val.status = 'failed';
                    val.message = '上传失败';
                    _file.status = -1;
                    _file.message = '上传失败';
                    this.$emit('fileChange', _file, this.fileList);
                  },
                  uploading: (chunk, allChunk) => {
                    let st = Math.floor((chunk / allChunk) * 100);
                    this.progress = st;
                    val.message = `上传进度${st}%`;
                    _file.message = `上传进度${st}%`;
                  },
                });
                return false;
            },
            getItemClassName(status){
                switch (status){
                    case 0:
                        return 'up';
                    case 1:
                        return 'success';
                    case -1:
                        return 'fail';
                    default:
                        return '';
                }
            },
            // 播放视频
            play(file){
                if(file.status != 1){
                    return;
                }
                window.open(file.path);
            },
            getVideoPoster(url){
                console.log('获取截屏', url);
                return new Promise((resolve, reject)=>{
                    var dataUrl = '';
                    var video = document.createElement('video');
                    video.setAttribute('crossorigin', 'anonymous');
                    video.setAttribute('width', 300);
                    video.setAttribute('hight', 200);
                    video.setAttribute('muted', true);
                    video.setAttribute('controls', true);
                    video.setAttribute('autoplay', 'autoplay');
                    video.setAttribute('src', cls);
                    video.currentTime = 1;
                    video.addEventListener('loadstart', ()=> {
                        console.log('开始加载视频');
                    })
                    video.addEventListener('loadeddata', ()=> {
                        console.log('获取截屏加载数据');
                        var canvas = document.createElement('canvas');
                        var width = video.width;
                        var height = video.height;
                        canvas.width = width;
                        canvas.height = height;
                        canvas.getContext('2d').drawImage(video, 0, 0, width, height);
                        dataUrl = canvas.toDataURL('image/jpeg', 0.5);
                        resolve(dataUrl);
                    })
                    video.addEventListener('error', (err)=> {
                        console.log('获取截屏错误');
                        reject(err);
                    })
                })
            },
            clear(){
                this.fileList = [];
                this.progress = 0;
                this.up = false;
                this.dataList = [];
                this.src = '';
                this.fileMName = '';
            },
        }
    }
</script>

<style scoped>
    .uploader-container{
        font-size: 14px;
        position: relative;
        /* padding-top:10px ; */
        text-align: left;
        padding: 10px 10px;
    }
    .uploader-container .button-box{
        /* position: absolute;
        right: 20px;
        top: 0;
        width: 120px; */
    }
    .preview-box1{
        min-height: 160px;
        position: relative;
    }
    .my-file-list{
        min-height: 100px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    .my-file-list .file-box{
        position: relative;
        width: 33%;
        /* height: 33%; */
    }
    
    .file-box .file-inner{
        border: 1px solid #f2f2f2;
        margin: 10px;
    }
    .file-box .text{
        line-height: 28px;
        padding: 0 5px;
        overflow: hidden;
        white-space: nowrap; /* 防止文字换行 */
        text-overflow: ellipsis; /* 超出部分显示省略号 */
    }
    .file-box .file-del{
        position: absolute;
        font-size: 24px;
        color: #ee0a24;
        top: 8px;
        right: 0px;
        z-index: 999;
        background: #fff;
        border-radius: 50%;
    }
    .file-box .loading, .file-box.fail .loading{
        display: none;
    }
    .file-box .fail-box, .file-box.up .fail-box{
        display: none;
    }
    .my-file-list .up .file-del{
        display: none;
    }
    .my-file-list .up .file-inner, .my-file-list .fail .file-inner{
        visibility: hidden;
    }
    .my-file-list .up .loading{
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        inset: 20px 10px;
        background-color: rgba(0, 0, 0, .7);
        z-index: 888;
        font-size: 14px;
        color: #fff;
        border-radius: 3px;
    }
    .my-file-list .fail .fail-box{
        display: flex;
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, .6);
        color: #fff;
        inset: 20px 10px;
        line-height: 30px;
        font-size: 14px;
        min-height: 80px;
        border-radius: 3px;
    }
    .file-item{
        position: relative;
        display: flex;
        width: 90%;
        margin: 5px 20px;
        padding-bottom: 5px;
        justify-content: space-between;
        font-size: 16px;
    }
    .item-progress{
        width: 100%;
        position: absolute;
        bottom: 0;
        right: 0;
    }
    .tips{
        font-size: 14px;
        color:#666;
        text-align: left;
        padding: 5px 20px;
    }
</style>