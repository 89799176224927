<template>
    <van-popup v-model="show" position="left" :close-on-click-overlay="true" :style="{ width: '90%',height:'90%',margin:'0 5%'}" @close="close">
        <div class="notice-wrap">
            <div class="notice-container" v-if="info">
                <div class="list-item">
                    <h4>{{info.title}}</h4>
                </div>
                <van-divider />    
                <div class="list-item desc-content"  v-html="info.desc">
                    
                </div>
            </div>
        </div>
        
        
        
        <div class="pop-footer">
            <van-divider />
            <div class="button-box">
                <van-button
                    type="info" size="small" class="pop-button"  @click="onConfirm(1)">是</van-button>
                <van-button
                    type="info" size="small" class="pop-button" @click="onConfirm(2)">否</van-button>
                <van-button
                    type="info" size="small" class="pop-button" @click="onConfirm(3)">不适用</van-button>
            </div>
            
        </div>
        
    </van-popup>
</template>

<script>
    //import { Notify } from 'vant';
    export default {
        
        data() {
            return{
                show: false,
                info: null
            }
        },
        methods:{
            onConfirm(val){
                // 提交
                this.$parent.saveStagePoint(val);
                this.show = false;
            },
            close(){
                this.info = null;
            }
        },
    }
</script>

<style scoped>
    .notice-wrap{
        width: 100%;
        position: relative;
        height: calc(100% - 8px);
        /* background-color: #fdf; */
        text-align: left;
    }
    .notice-container{
        height: 100%;
        padding: 5px 10px;
    }
    .desc-content{
        height: calc(100% - 110px);
        overflow: auto;
        
    }
    .pop-footer{
        position: absolute;
        width: 100%;
        bottom: 0;
    }
    
    .button-box{
        display: flex;
        justify-content: center;
        padding-bottom: 5px;
        text-align: center;
    }
    .button-box .pop-button{
        width: 80px;
        margin-right: 10px;
    }
</style>