<template>
    <van-popup v-model="show" position="top" @close="close">
        <div class="preview-box1">
            <div class="list-item">
                <h4 class="h4">选择当前工序</h4>
                <van-radio-group v-model="result" direction="horizontal">
                  <van-radio :name="item.id" class="radio-item"
                    v-for="item in options" :key="item.id">{{item.name}}</van-radio>
                </van-radio-group>
            </div>
                
            <div class="list-item" v-show="getShow()">
                <h4 class="h4">选择质检工序</h4>
                <van-radio-group v-model="result2" direction="horizontal">
                  <van-radio :name="item.id" class="radio-item"
                    v-for="item in options2" :key="item.id">{{item.name}}</van-radio>
                </van-radio-group>
            </div>
        </div>
        
        <van-divider />
        <van-button 
            type="info" style="margin: 10px;" @click="onConfirm2">确&nbsp;&nbsp;&nbsp;&nbsp;认                </van-button>
    </van-popup>
</template>

<script>
    import { Notify } from 'vant';
    import { getMesTaskStagList } from '@/api/task.js';
    import { MesStageTypeDict } from '@/utils/config.js';
    // import { toast } from '@/utils/common.js';
    export default {
        data() {
            return{
                show: false,
                result: 0,
                result2: 0, 
                options: [],
                options2: [],
            }
        },
        created() {
            this.getTaskStage();
        },
        methods:{
            // onchange(val){
            //     console.log('val', val);
            //     var found = this.options.find((item)=> item.id == val);
            //     this.stage = found;
            // },
            // onchange2(val){
            //     var found = this.options2.find((item)=> item.id == val);
            //     this.tstage = found;
            // },
            getShow(){
                if(!this.result){
                   return false; 
                }
                var found = this.options.find((item)=> item.id == this.result);
                if(!found){
                   return false; 
                }
                if(found.type == MesStageTypeDict.check){
                    return true; 
                }
                return false; 
            },
            getTaskStage(){
                getMesTaskStagList().then((ret)=>{
                    console.log('data', ret.data);
                    this.options = ret.data;
                    this.options2 = ret.data.filter((item)=> item.type == MesStageTypeDict.normal);
                }).catch((err)=>{
                    Notify({ type: 'warning', message: err.msg || '获取工序失败'});
                })
            },
            onConfirm2(){
                console.log(this.stage);
                
                if(!this.result){
                    return Notify({ type: 'warning', message:'请选择工序'});
                }
                // 如果是质检程序，还需要选择检测的对象
                var stage = this.options.find((item)=> item.id == this.result);
                if(stage && stage.type == MesStageTypeDict.check){
                    if(!this.result2){
                        return Notify({ type: 'warning', message:'请选择要质检的工序'});
                    }
                    var tstage = this.options2.find((item)=> item.id == this.result2);
                    this.$emit('on-confirm', stage, tstage);
                }else{
                    this.$emit('on-confirm', stage);
                }
                
                // 提交
                //this.$parent.onConfirmFinish(data);
                this.show = false;
            },
            close(){
                this.result = 0;
                this.result2 = 0;
                // this.stage = {};
                // this.tstage = {};
            },
            // 获取有效的文件
        }
    }
</script>

<style scoped>
    .uploader-container{
        font-size: 14px;
        position: relative;
        /* padding-top:10px ; */
        text-align: left;
        padding: 10px 10px;
    }
    .uploader-container .button-box{
        /* position: absolute;
        right: 20px;
        top: 0;
        width: 120px; */
    }
    .preview-box1{
        min-height: 160px;
        position: relative;
        font-size: 14px;
    }
    .list-item{
        margin: 10px 20px;
    }
    .list-item .textarea{
        background-color: #f2f2f2;
        padding: 5px;
    }
    .radio-item{
        margin: 5px 10px;
    }
    .h4{
        font-size: 15px;
        text-align: left;
        font-weight: bold;
        text-indent: 10px;
    }
</style>