<template>
    <van-popup v-model="show" position="top" @close="close">
        <div class="preview-box1">
            <div class="list-item">
                <upload-image-item @fileChange="fileChange" ref="uploader"></upload-image-item>
            </div>
        </div>
        <van-divider />
        <van-button 
            type="info" style="margin: 10px;" @click="onConfirm">确&nbsp;&nbsp;&nbsp;&nbsp;认                </van-button>
    </van-popup>
</template>

<script>
    import { Notify } from 'vant';
    import UploadImageItem from '@/components/mes/UploadImageItem.vue';
    // import { toast } from '@/utils/common.js';
    export default {
        components:{
           UploadImageItem 
        },
        data() {
            return{
                show: false,
                fileList: [],
            }
        },
        props:['confirm'],
        methods:{
            onConfirm(){
                var files = this.getVilidFiles();
                if(!files){
                    return Notify({ type: 'warning', message: '需要上传该工序的图片。' });
                }
                this.show = false;
                // console.log()
                // 提交
                this.$parent.onConfirmFinish({
                    imgs: files,
                    remark: '',
                    video: '',
                });
                
            },
            fileChange(file, list){
                console.log('file', list);
                this.fileList = list;
            },
            close(){
                this.fileList = [];
                this.$refs.uploader.clear();
            },
            // 获取有效的文件
            getVilidFiles(){
                //var fileStr = '';
                var list = this.fileList.filter((item)=>{
                    return item.status == 'sucess';
                });
                if(list.length == 0){
                    return '';
                }
                return list.map((item)=> item.file.url).join(',');
            },
        }
    }
</script>

<style scoped>
    .uploader-container{
        font-size: 14px;
        position: relative;
        /* padding-top:10px ; */
        text-align: left;
        padding: 10px 10px;
    }
    .uploader-container .button-box{
        /* position: absolute;
        right: 20px;
        top: 0;
        width: 120px; */
    }
    .preview-box1{
        min-height: 160px;
        position: relative;
    }
    .list-item{
        margin: 10px 20px;
    }
    .list-item .textarea{
        background-color: #f2f2f2;
        padding: 5px;
    }
</style>