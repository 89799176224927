<template>
    <van-popup v-model="show" position="top" @close="close">
        <div class="preview-box1">
            <div class="list-item" v-for="item in stageList" :key="item.id">
                <van-cell-group>
                    <van-cell
                    :title="item.name"
                        title-class="my-title-cls"
                        >
                        <van-radio-group class="flex" v-model="values[item.id]">
                          <van-radio name="1">合格</van-radio>
                          <span style="display: inline-block;width: 20px;"></span>
                          <van-radio name="-1" class="margin-left40">不合格</van-radio>
                        </van-radio-group>
                    </van-cell>
                </van-cell-group>
            </div>
                
            <div class="list-item">
                <van-field
                  class="textarea"
                  v-model="remark"
                  rows="3"
                  autosize
                  type="textarea"
                  placeholder="总检说明"
                />
            </div>
            <div class="list-item">
                <upload-image-item @fileChange="fileChange" ref="uploader"></upload-image-item>
            </div>
        </div>
        
        <van-divider />
        <van-button 
            type="info" style="margin: 10px;" @click="onConfirm">确&nbsp;&nbsp;&nbsp;&nbsp;认          </van-button>
    </van-popup>
</template>

<script>
    import { Notify } from 'vant';
    import { getMesTaskStagList } from '@/api/task.js';
    import { MesStageTypeDict } from '@/utils/config.js';
    import UploadImageItem from '@/components/mes/UploadImageItem.vue';
    // import { toast } from '@/utils/common.js';
    export default {
        components:{
           UploadImageItem 
        },
        data() {
            return{
                show: false,
                fileList: [],
                remark: '',
                result: 0,
                stageList: [],
                values: {},
                imgs: '',
            }
        },
        created() {
            this.getTaskStage();
        },
        methods:{
            onConfirm(){
                if(this.stageList.length == 0){
                    return;
                }
                var isfail = true;
                var values = {};
                for (var i = 0; i < this.stageList.length; i++) {
                    var stage = this.stageList[i];
                    var val = this.values[stage.id];
                    if(typeof(val) == 'undefined'){
                        return Notify({ type: 'warning', message: '每个工序都必须给出结果' });
                    }
                    isfail = isfail && val != -1;
                    values[stage.id] = val;
                }
                var files = this.getVilidFiles();
                // 如果有不合格
                if(!isfail){
                   if(!files || !this.remark){
                       return Notify({ type: 'warning', message: '有不合格工序，必须给出总检说明，和不合格图片' });
                   } 
                }
                
                var data = {
                    result: values,
                    remark: this.remark,
                    imgs: files,
                    'video': '',
                };
                console.log('data', data);
                // 提交
                this.$parent.onConfirmFinish(data);
                this.show = false;
            },
            fileChange(file, list){
                this.fileList = list;
            },
            close(){
                this.fileList = [];
                this.remark = '';
                this.values = {};
                this.$refs.uploader.clear();
            },
            // 获取有效的文件
            getVilidFiles(){
                //var fileStr = '';
                var list = this.fileList.filter((item)=>{
                    return item.status == 'sucess';
                });
                if(list.length == 0){
                    return '';
                }
                return list.map((item)=> item.file.url).join(',');
            },
            getTaskStage(){
                getMesTaskStagList().then((ret)=>{
                    this.stageList = ret.data.filter((item)=> item.type == MesStageTypeDict.normal);
                }).catch((err)=>{
                    Notify({ type: 'warning', message: err.msg || '获取工序失败'});
                })
            },
        }
    }
</script>

<style scoped>
    .uploader-container{
        font-size: 14px;
        position: relative;
        /* padding-top:10px ; */
        text-align: left;
        padding: 10px 10px;
    }
    .uploader-container .button-box{
        /* position: absolute;
        right: 20px;
        top: 0;
        width: 120px; */
    }
    .preview-box1{
        min-height: 160px;
        position: relative;
        text-align: left;
    }
    .list-item{
        margin: 10px 20px;
    }
    .list-item .textarea{
        background-color: #f2f2f2;
        padding: 5px;
    }
    .list-item .my-title-cls{
        color: #333;
        font-size: 15px;
        font-weight: bold;
    }
</style>