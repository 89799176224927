<template>
    <div class="mypage mes-task">
        <!-- <div class="nav-area">
            <van-button class="nav-button" round plain hairline ref="ball" @click="scanCard">
                <van-image
                  width="36"
                  height="36"
                  :src="changeIcon"
                />
            </van-button>
        </div> -->
        <div class="page-body">
            <van-cell-group  class="body-top">
                <van-cell
                    :label="getLabel()"
                    label-class="cell-label van-multi-ellipsis--l2"
                    title-class="cell-title"
                    value-class="cell-value"
                    >
                    <template slot="title">
                      <div class="flex-align cell-title">
                        <span class="font-size3 bold">任务编码</span>
                        <span class="margin-left10 font-size1 van-color-text-regular" v-if="task"
                          > {{task.task_no}}</span>
                        <van-tag type="warning" style="margin-left: 15px;" v-if="isTaskFinish">已完成</van-tag>
                      </div>
                    </template>
                    <template>
                        <van-icon name="scan" class="my-icon" 
                            color="#40a9ff" size="30" @click="scan" />
                    </template>
               </van-cell
>
                <van-cell
                    style="min-height: 60px;"
                    title-class="cell-title"
                    value-class="flex-align-end cell-value">
                    <template slot="title">
                      <div class="flex-align cell-title">
                        <span class="">当前工序</span>
                        <van-tag type="warning" style="margin-left: 8px;" v-if="isFinish">已完成</van-tag>
                      </div>
                    </template>
                    <template slot="label">
                      <div class="flex-align cell-label">
                        <span class="font-size2 bold">{{stage?stage.name:''}}</span>
                        <span v-if="tstage">
                            <span class="margin-left10 font-size2"
                              > <van-icon name="arrow" />{{tstage.name}}</span
                            >
                        </span>
                      </div>
                    </template>
                    <template>
                        <van-icon name="search" class="my-icon" color="#40a9ff" size="30" @click="popStage()" />
                    </template>
                </van-cell>
            </van-cell-group>
            <div class="main">
                <div class="btn-fold" @click="forcePopPoint()">
                    <van-image :src="viewPointIcon" />
                </div>
                <van-grid :border="false" :column-num="2">
                    <van-grid-item >
                        <van-button 
                            class="task-button" color="#00cc00" :disabled="dis1" @click="onStart()"
                        >开始</van-button>
                    </van-grid-item>
                    <van-grid-item >
                        <van-button 
                            class="task-button" color="#00cccc" :disabled="dis2" @click="onJoin()"
                        >加入</van-button>
                    </van-grid-item>
                    <van-grid-item >
                        <van-button 
                            class="task-button" color="#616161" :disabled="dis3" @click="onPause()"
                        >暂停</van-button>
                    </van-grid-item>
                    <van-grid-item >
                        <van-button 
                            class="task-button" color="#00cccc" :disabled="dis4" @click="onContinue()"
                        >继续</van-button>
                    </van-grid-item>
                    <van-grid-item >
                        <van-button 
                            class="task-button" color="#cc0000" :disabled="dis5" @click="onLeave()"
                        >离开</van-button>
                    </van-grid-item>
                    <van-grid-item >
                        <van-button 
                            class="task-button" color="#00cc00" :disabled="dis6" @click="onFinish()"
                        >完成</van-button>
                    </van-grid-item>
                </van-grid>
            </div>
        </div>
        <div class="notice-box" v-if="noticeText">
            <van-notice-bar
              speed="30"
              scrollable
              color="#108ee9"
              background="#e6f7ff"
              left-icon="info"
            >{{noticeText}}</van-notice-bar>
        </div>
        <div class="page-footer">
            <van-grid class="footer-grid" :border="false" :column-num="2">
                <van-grid-item >
                    <van-button 
                        class="task-button" color="#cc0000" @click="onCallException()"
                    >异常</van-button>
                </van-grid-item>
                <van-grid-item >
                    <router-link style="display: block;width: 100%;" to="/mes/order/my">
                        <van-button 
                            class="task-button" color="#00cccc"
                        >我的</van-button>
                    </router-link>
                </van-grid-item>
            </van-grid>
        </div>
        <!-- <van-action-sheet title="选择工序" v-model="sheetShow" :actions="actions" @select="onSelect" /> -->
        <pop-input-gin ref="popGin"></pop-input-gin>
        <pop-exception ref="popException"></pop-exception>
        <pop-pause :list="reasonList" ref="popPause"></pop-pause>
        <pop-common-finish ref="popCommonFinish"></pop-common-finish>
        <pop-test ref="popUploadVideo"></pop-test>
        <pop-detect ref="popDetect"></pop-detect>
        <pop-final-detect ref="popFinalDetect"></pop-final-detect>
        <pop-select-stage ref="popSelectStage" @on-confirm="selectStage"></pop-select-stage>
        <pop-scan-card ref="popScanCard"></pop-scan-card>
        <pop-stage-point ref="popStagePoint"></pop-stage-point>
    </div>
</template>

<script>
    import { checkLogin } from '@/utils/common.js';
    import { MesStageTypeDict } from '@/utils/config.js';
    import { Dialog, Notify } from 'vant';
    import { getMesTaskDetailStatus, getMesTaskExceptionList, getMesTaskStagePointList, updateMesTask, updateMesTaskStagePoint } from '@/api/task.js';
    import PopInputGin from '@/components/mes/PopInputGin.vue';
    import PopPause from '@/components/mes/PopPause.vue';
    import PopException from '@/components/mes/PopException.vue';
    import popCommonFinish from '@/components/mes/popCommonFinish.vue';
    import popTest from '@/components/mes/popTest.vue';
    import PopDetect from '@/components/mes/PopDetect.vue';
    import PopSelectStage from '@/components/mes/PopSelectStage.vue';
    import PopFinalDetect from '@/components/mes/PopFinalDetect.vue';
    import PopScanCard from '@/components/mes/PopScanCard.vue';
    import PopStagePoint from '@/components/mes/PopStagePoint.vue';
    // const stageIdKey = 'STAGEID';
    // const stageNameKey = 'STAGENAME';
    const stageKey = 'STAGE';
    const tstageKey = 'TARGETSTAGE';
    var taskid = null;
    var timer = null;
    var isPopPoint = {}; // 阻止因为定时器获取任务导致的反复弹框
    export default {
        components:{
            PopInputGin,
            PopException,
            PopPause,
            popTest,
            PopDetect,
            popCommonFinish,
            PopSelectStage,
            PopFinalDetect,
            PopScanCard,
            PopStagePoint
        },
        data() {
            return {
                // stageId: null,
                // stageName: '',
                task: null,
                taskUser: null,
                taskPointResult: null,
                // 按钮是否可用
                dis1: true,
                dis2: true,
                dis3: true,
                dis4: true,
                dis5: true,
                dis6: true,
                sheetShow: false,
                actions: [],
                reasonList: [],
                pointList: [],
                stage: null,
                tstage: null,
                curStageList:[], // 表示当前的工序
                isFinish: false,
                isTaskFinish: false,
                noticeText: '',
                changeIcon: require('../../assets/images/change-account.png'),
                viewPointIcon: require('../../assets/images/mes-fold.png'),
                queue: [], // 要执行的弹框队列
                // 队列
            }
        },
        // 传递参数会触发
        beforeRouteUpdate (to) {
            var id = to.params.taskid;
            console.log('taskid', id);
            this.onload(id);
        },
        deactivated() {
            clearTimeout(timer);
        },
        activated() {
            var id = this.$route.params.taskid;
            //var id = 48;
            this.onload(id);
            this.hideTab();
        },
        mounted() {
            // 
            // this.stageId = localStorage.getItem(stageIdKey);
            // this.stageName = localStorage.getItem(stageNameKey);
            //this.smooth();
            var str = localStorage.getItem(stageKey);
            if(str){
               var stage = JSON.parse(str);
               this.stage = stage;
               if(stage.type == MesStageTypeDict.check){
                   str = localStorage.getItem(tstageKey);
                   if(str){
                       this.tstage = JSON.parse(str);
                   }
               }
            }
            this.getTaskException();
            this.getTaskStagePoint();
        },
        methods:{
            forcePopPoint(){
                if(!this.task){
                    return;
                }
                var curStage = this.getCurStage();
                if(!curStage){
                    return;
                }
                this.queue = this.getPopStagePoint(1);
                this.viewPoint();
            },
            viewPoint(){
                // 触发弹出关键控制点
                // 加载新任务
                // 在有任务的情况下，切换工序
                // 主动打开弹框
                // var pop = this.$refs.popStagePoint;
                // pop.show = true;
                if(this.queue.length){
                    var pop = this.$refs.popStagePoint;
                    pop.info  = this.queue[0];
                    pop.show = true;
                }
            },
            
            saveStagePoint(val){
                // 查看是
                var item = this.queue[0];
                var result = this.taskPointResult.find((pr)=> pr.point_id == item.id);
                var data = {
                    task_id: taskid,
                    point_id: item['id'],
                    value: val,
                };
                var id = 0
                if(result){
                    id = result['id'];
                }
                updateMesTaskStagePoint(id, data).then(()=>{
                    this.queue.splice(0,1);
                    setTimeout(()=>{
                        this.viewPoint();
                    }, 500)
                }).catch((err)=>{
                    Notify({ type: 'warning', message: err.message || '保存失败'});
                })
            },
            getLabel(){
                if(this.task){
                   return  this.task.title;
                }
                return  '任务描述';
            },
            scanCard(){
                var pop = this.$refs.popScanCard;
                pop.show = true;
                
                setTimeout(()=> {
                    var cardNo = '14:be:44:60';
                    //pop.message = '读取成功，正在获取员工信息';
                    pop.getUser(cardNo);
                },1000)
            },
            reboot(){
                if(timer){
                    var id = this.$route.params.taskid;
                    this.onload(id);
                }
            },
            updateMesTask(id, type, data){
                return new Promise((resove, reject) =>{
                    checkLogin().then(()=> {
                        updateMesTask(id, type, data).then((ret)=>{
                            resove(ret);
                        }).catch((err)=>{
                            reject(err);
                        })
                    })
                })
            },
            popGin(){
                this.$refs.popGin.show = true;
            },
            confirmBindGin(gin){
                console.log('bind',gin);
                var data = {
                    gin: gin,
                    stage: this.stage.id,
                    action: this.stage.name,
                };
                this.updateMesTask(taskid, 'start', data).then(()=>{
                    this.getTask();
                }).catch((err)=>{
                    Notify({ type: 'warning', message: err.message || '开始任务失败'});
                })
            },
            
            onStart(){
                var that = this;
                if(!this.task || !this.stage){
                    return;
                }
                if(this.task['status'] == 3){
                    Notify({ type: 'warning', message: '任务已完成'});
                    return;
                }
                // 如果有其它未关闭的工时
                if(this.taskUser && this.taskUser['stage_rid']){
                   return Dialog.confirm({
                      title: '提示',
                      message: '你有正在进行的任务，确认离开？',
                    }).then(() => {
                        // 修改自己状态 关闭未完成的工时
                        if(that.task['status'] == 0){
                            this.onLeave(this.popGin);
                        }else{
                            this.onLeave(start);
                        }
                        
                    })
                }
                
                start();
                // 执行开始
                function start(){
                    // 如果是装配 且任务没有开始
                    if(that.task['status'] == 0){
                       if(that.stage['type'] == MesStageTypeDict.check){
                           Notify({ type: 'warning', message:'任务尚未开始，不能直接进行质检！'});
                           return;
                       }
                       
                       if(that.stage['type'] == MesStageTypeDict.final){
                           Notify({ type: 'warning', message:'任务尚未开始，不能直接进行总检！'});
                           return;
                       }
                       
                       return that.popGin();
                    }
                    var curStage = that.getCurStage();
                    if(curStage && curStage[`status`] != 0){
                        Notify({ type: 'warning', message:'任务在进行中，不能重复开始'});
                        return;
                    }
                    
                    // 开始
                    var data = {
                        stage: that.stage.id,
                        action: that.stage.name,
                    };
                    // 如果是质检
                    if(that.stage['type'] == MesStageTypeDict.check){
                        if(!that.tstage){
                            Notify({ type: 'warning', message:'请选择要检验的工序'});
                            return;
                        }
                        data.stage_tsid = that.tstage.id;
                        data.action =  `${that.stage.name}->${that.tstage.name}`;
                    }
                    that.updateMesTask(taskid, 'startStage', data).then(()=>{
                        that.getTask();
                    }).catch((err)=>{
                        Notify({ type: 'warning', message: err.message || '开始任务失败'});
                    })
                }
                
            },
            onPause(){
                console.log('pause');
                this.$refs.popPause.show = true;
                // 弹框 
            },
            confirmPause(reason){
                console.log('reason', reason);
                var curStage = this.getCurStage();
                var data = {
                    reason   : reason,
                    stage    : this.stage.id,
                    imgs     : '',
                    stage_rid: curStage.id,
                };
                this.updateMesTask(taskid, 'pause', data).then(()=>{
                    this.getTask();
                }).catch((err)=>{
                    Notify({ type: 'warning', message: err.message || '暂停失败'});
                })
            },
            onLeave(callback){
                //var curStage = this.getCurStage();
                var data = {
                    stage     : this.stage.id,
                    //stage_rid: curStage.id,  // 切换到新工艺 就找不到该选项了。
                };
                this.updateMesTask(taskid, 'leave', data).then(()=>{
                    if(callback){
                        return callback();
                    }
                    this.getTask();
                }).catch((err)=>{
                    Notify({ type: 'warning', message: err.message || '离开失败'});
                })
            },
            onContinue(){
                var curStage = this.getCurStage();
                var data = {
                    stage     : this.stage.id,
                    stage_rid: curStage.id,
                };
                this.updateMesTask(taskid, 'continue', data).then(()=>{
                    this.getTask();
                    //this.task['status'] = 1;
                    // 修改后 获取
                    //this.manageButtons();
                }).catch((err)=>{
                    Notify({ type: 'warning', message: err.message || '继续失败'});
                })
            },
            onJoin(){
                var curStage = this.getCurStage();
                var data = {
                    stage     : this.stage.id,
                    stage_rid: curStage.id,
                };
                this.updateMesTask(taskid, 'join', data).then(()=>{
                    this.getTask();
                    //this.task['status'] = 1;
                    // 修改后 获取
                    //this.manageButtons();
                }).catch((err)=>{
                    Notify({ type: 'warning', message: err.message || '加入失败'});
                })
            },
            onFinish(){
                // 1普通工序 上传图片 2测试 上传视频  3 检验 合格｜不合格 4 总检 所有普通工序 
                //var pop = '';
                if(this.stage.type == MesStageTypeDict.normal){
                    if(this.stage.result_type == 'video'){
                        this.$refs.popUploadVideo.show = true;
                    }else{
                        this.$refs.popCommonFinish.show = true;
                    }
                }else if(this.stage.type == MesStageTypeDict.check){
                    this.$refs.popDetect.show = true;
                }else if(this.stage.type == MesStageTypeDict.final){
                    this.$refs.popFinalDetect.show = true;
                }
                
            },
            onConfirmFinish(data){
                var curStage = this.getCurStage();
                if(!curStage){
                    return;
                }
                data.stage_rid = curStage.id;
                data.stage = this.stage.id;
                this.updateMesTask(taskid, 'finish', data).then(()=>{
                    this.getTask();
                    //this.task['status'] = 1;
                    // 修改后 获取
                    //this.manageButtons();
                }).catch((err)=>{
                    console.log('err', err);
                    Notify({ type: 'warning', message: err.message || '完成失败'});
                })
                // 装配 电装 上传图片
                // 测试 单独的弹框
                // 质检 单独的弹框
            },
            onCallException(){
                // 要看任务 是否存在
                this.popGin();
                if(!this.task){
                   return; 
                }
                if(this.task[`status`] == 0){
                    Notify({ type: 'warning', message: '该任务尚未开始' });
                    return; 
                }
                var curStage = this.getCurStage();
                if(!curStage){
                    return;
                }
                
                if(curStage[`status`] == 0){
                   Notify({ type: 'warning', message: '该工序尚未开始' });
                   return; 
                }
                if(curStage[`status`] == 2){
                   Notify({ type: 'warning', message: '该工序已经停止' });
                   return; 
                }
                if(curStage[`status`] == 3){
                   Notify({ type: 'warning', message: '该工序已经完成' });
                   return; 
                }
                this.$refs.popException.show = true;
            },
            confirmCallException(text, imgs){
                if(!this.task){
                   return; 
                }
                if(this.task[`status`] == 0){
                    Notify({ type: 'warning', message: '该任务尚未开始' });
                    return; 
                }
                var curStage = this.getCurStage();
                if(!curStage){
                    return;
                }
                var data = {
                    stage    : this.stage.id,
                    reason   : text,
                    imgs     : imgs,
                    stage_rid: curStage.id,
                };
                this.updateMesTask(taskid, 'exception', data).then(()=>{
                    this.getTask();
                }).catch((err)=>{
                    Notify({ type: 'warning', message: err.message || '申报异常失败'});
                })
            },
            disableBtns(){
                this.dis1 = true;
                this.dis2 = true;
                this.dis3 = true;
                this.dis4 = true;
                this.dis5 = true;
                this.dis6 = true;
            },
            popStage(){
                //this.sheetShow = true;
                this.$refs.popSelectStage.show = true;
                //this.popSelectStage()
            },
            
            onload(id){
                if(id == 0){
                   // 清空页面数据
                   taskid = id;
                   this.disableBtns();
                   return;
                }
                taskid = id;
                this.getTask();
                // 加载数据
                
            },
            getPopStagePoint(force){
                // 获取需要打开的弹层列表数据
                if(this.pointList.length == 0){
                    // 所有的关键点 ，所属工序筛选，结果筛选
                   return [];
                }
                // 未选工序
                if(!this.stage){
                    return [];
                }
                
                
                // 属于这个工序，且 没有结果的
                var _ps = this.pointList.filter((item)=> {
                    // 是否强制1
                    if(force){
                        return item.stage_id == this.stage.id;
                    }else{
                        var found = this.taskPointResult.find((pr)=> pr.point_id == item.id);
                        return item.stage_id == this.stage.id && !found;
                    }
                })
                return _ps;
            },
            getTask(){
                // 获取任务
                clearTimeout(timer);
                checkLogin().then(()=> {
                    getMesTaskDetailStatus(taskid).then((ret)=>{
                       
                        var data = ret.data;
                        this.task = data.task;
                        this.taskPointResult = data.pointResult;
                        // 获取需要打开的弹层列表数据
                        if(!isPopPoint[taskid]){
                            isPopPoint[taskid] = true;
                            this.queue = this.getPopStagePoint();
                            this.viewPoint();
                        }
                        this.taskUser = data.taskUser;
                        this.curStageList = data.stageResultList;
                        if(data.task.gin){
                            var str = `GIN编码:${data.task.gin} 柴油机原机型号:${data.task.original_model||'-'} 柴油机体钢印号:${data.task.body_no||'-'} 电机真实功率:${data.task.motor_power||'-'} 电机钢印号:${data.task.steal_no||'-'}`;
                            this.noticeText = str;
                            //console.log('str', this.noticeText != str);
                        }else{
                            this.noticeText = '';
                        }
                        if(this.task['status'] == 3){
                            this.isTaskFinish = true;
                        }else{
                            this.isTaskFinish = false;
                        }
                        console.log('status',this.task['status'] == 3);
                        // 如果 任务未开始，自动选择装配
                        if(!this.stage){
                          this.disableBtns();
                          return Notify({ type: 'warning', message: '请先选择工序'});
                        }
                        timer =  setTimeout(()=> {
                             this.getTask();
                         }, 5000);
                        this.initPage();
                        
                    }).catch((err)=>{
                        console.log('err', err);
                        Notify({ type: 'warning', message: err.message || '获取任务失败'});
                    })
                })
            },
            getTaskException(){
                getMesTaskExceptionList().then((ret)=>{
                    console.log('data', ret.data);
                    this.reasonList = ret.data;
                }).catch((err)=>{
                    Notify({ type: 'warning', message: err.msg || '获取异常失败'});
                })
            },
            getTaskStagePoint(){
                getMesTaskStagePointList().then((ret)=>{
                    console.log('data111', ret.data);
                    this.pointList = ret.data;
                }).catch((err)=>{
                    Notify({ type: 'warning', message: err.msg || '获取工序关键点失败'});
                })
            },
            // getTaskStage(){
            //     getMesTaskStagePoint().then((ret)=>{
            //         console.log('data', ret.data);
            //         this.pointList = ret.data;
            //     }).catch((err)=>{
            //         Notify({ type: 'warning', message: err.msg || '获取工序关键点失败'});
            //     })
            // },
            //TODO 有目标工序
            selectStage(stage, tstage){
                // this.stageId = stage.id;
                // this.stageName = stage.name;
                this.stage = stage;
                localStorage.setItem(stageKey, JSON.stringify(stage));
                if(stage.type == MesStageTypeDict.check){
                    this.tstage = tstage;
                    localStorage.setItem(tstageKey, JSON.stringify(tstage));
                }else{
                    this.tstage = null;
                    localStorage.setItem(tstageKey, '');
                }
                // localStorage.setItem(stageIdKey, stage.id);
                // localStorage.setItem(stageNameKey, stage.name);
                //this.sheetShow = false;
                // TODO 还需要控制按钮是否能用
                if(this.task){
                    this.initPage();
                }else{
                    this.disableBtns();
                }
                
            },
            scan(){
                //this.$router.replace('/mes/order/task/48');
                // return;
                this.$dd.biz.util.scan({
                    type: 'qrCode',
                    onSuccess: function(data) {
                        const { text } = data;
                        location.href = text;
                        // var path = text.replace(`${domain}/#`, '');
                        // that.$router.replace(path);
                    },
                });

            },
            hideTab(){
                //console.log(document.querySelector('#vantab'));
                document.querySelector('#vantab').classList.add('tab-hide');
            },
            initPage(){
            
                // 初始化界面
                // 根据任务 1 如果当前工序未开启 ，开始按钮 激活
                // 1 如果当前按钮 已完成 所有按钮关闭
                // console.log('新任务', this.task[`${this.stageId}_status`]);
                this.disableBtns();
                this.isFinish = false;
                
                if(!this.task || !this.stage){
                    return;
                }
                if(this.task.status == 0){
                    this.dis1 = false;
                    return;
                }
                // 获取当前工序的状态
                var curStage = this.getCurStage();
                if(!curStage){
                    this.dis1 = false;
                    return;
                }
                //TODO 0 未开始 1 进行中 2 停止中 3 已完成
                if(curStage.status == 0){
                    this.dis1 = false;
                    return;
                }
                if(curStage.status == 3){
                    this.isFinish = true;
                    return;
                }else{
                     // ==1  2
                     var isMatch = this.isMatchStage(curStage);
                     if(isMatch){
                         // 继续 暂停
                         if(curStage.status == 1){
                            this.dis3 = false;
                            this.dis6 = false;
                         }else if(curStage.status == 2){
                            this.dis4 = false;
                         }
                         this.dis5 = false;
                         return;
                     }else{
                         // 加入
                         this.disableBtns();
                         return this.dis2 = false;
                     }
                }
            },
            getCurStage(){
                // 如果未查到已做工序的相关信息，返回null
                var list = this.curStageList;
                if(list.length == 0){
                    return null;
                }
                // 
                var fond;
                if(this.stage.type == MesStageTypeDict.check){
                    // 如果是质检
                    fond = list.find((l)=> {
                       return l.stage_id == this.stage.id && l.target_stage_id == this.tstage.id;
                    })
                    return fond;
                }else{
                    fond = list.find((l)=> {
                       return l.stage_id == this.stage.id;
                    })
                    return fond;
                }
                // 质检
            },
            // 工序是否匹配当前任务
            isMatchStage(cur){
                // 未查到我的工作状态
                if(!this.taskUser){
                    return false;
                }
                // 我的工作状态 的任务 和当前任务匹配 工序也匹配 就可以是匹配的。
                if(this.taskUser['task_id'] == taskid && this.taskUser['stage_rid'] == cur['id']){
                    return true;
                }
                return false;
            },
            smooth(){
               var ball = this.$refs.ball;
               var doc = window.document;
               var maxHeight = 140 + 300 - 50;
               var minHeight = 140 - 50;
               // 绑定事件
               //ball.removeEventListener('mousedown', onMousedown);
               ball.onmousedown = onMousedown;
               function onMousedown(evt){
                   evt.preventDefault();
                   //doc.removeEventListener('mousemove', onMove);
                   doc.onmousemove = onMove;
                   //doc.addEventListener('mousemove', onMove);
               }
               function onMove(evt){
                   evt.preventDefault();
                    requestAnimationFrame(()=>{
                        if(evt.pageY < maxHeight && evt.pageY > minHeight){
                            ball.style.transform = `translateY(${evt.pageY - 100}px)`;
                        }
                        
                    })
               }
               
               function onMouseup(evt){
                   evt.preventDefault();
                   doc.onmousemove = null;
               }
               doc.onmouseup = onMouseup;
            },
        },
    }
</script>

<style scoped>
    @import url('../../assets/css/common.css');
    .mypage{
        height: 100%;
        padding-bottom: 0;
    }
    .inner-box{
        margin-left: 0px;
        margin-right: 0px;
    }
    .box-bottom-shadow{
        box-shadow: 1px 3px 7px 0px #ccc;
    }
    .body-top{
        border-bottom: 1px solid #ccc;
        padding-bottom: 3px;
    }
    .main{
        position: relative;
        margin-top: 5px;
        padding: 30px 0;
        height: calc(100% - 200px);
        overflow: auto;
    }
    .task-button{
        width: 100px;
        height: 100px;
        border-radius: 50%;
        font-size: 18px;
    }
    .page-body{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 120px;
        overflow: auto;
    }
    .page-footer{
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-box-shadow: 1px -2px 2px 0px #ccc;
        box-shadow: 1px -2px 2px 0px #ccc;
    }
    .footer-grid{
        width: 100%;
    }
    .page-footer .task-button{
        width: 100%;
        height: 50px;
        border-radius: 10px;
        font-size: 18px;
    }
    .cell-title{
        font-size: 15px !important;
        color: #666666 !important;
        text-align: left;
        font-weight: bold;
        flex: 4 !important;
    }
    .cell-label{
        font-size: 16px !important;
        color: #333333 !important;
        /* font-weight: bold; */
    }
    .cell-value{
        text-align: center;
        position: relative;
    }
    .my-icon{
        position: absolute;
        inset: 0;
        padding: 10px;
    }
    .notice-box{
        position: fixed;
        left: 0;
        right: 0;
        bottom: 80px;
    }
    .movable-area{
        position: fixed;
        width: 60px;
        height: 240px;
        top: 130px;
        right: 0;
        background-color: #ff0;
        z-index: 999;
    }
    
    /* 导航区域 */
    .nav-area{
        position: fixed;
        width: 60px;
        height: 300px;
        top: 140px;
        right: 0px;
        /* background-color: #f2f200; */
        z-index: 888;
        /* border: 1px solid #ccc; */
        pointer-events:none;
    }
    .nav-area .nav-button{
        border-radius: 50%;
        height: 50px;
        width: 50px;
        margin-right: -10px;
        transition: transform linear .1s;
        pointer-events:all;
        border: rgba(64, 169, 255, 0.8);
        /* background-color: rgba(64, 169, 255, 0.7); */
    }
    .btn-fold{
        display: inline-block;
        width: 40px;
        height: 40px;
        position: fixed;
        right: 0;
        top: 140px;
        z-index: 100;
    }
</style>