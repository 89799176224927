<template>
    <van-popup v-model="show" closeable close-on-click-overlay :style="{ width: '80%',}" @close="close()">
        <div class="card-info my-form">
            <van-divider content-position="left" :style="{padding: '20px 0 0',margin: '0 0'}">当前登录信息</van-divider>
            <div class="info-item" v-if="info">
                <van-cell class="my-cell" 
                    :title="info.dingtalk.nickname" 
                    :label="info.dingtalk.station"
                    value-class="my-value-cls"
                >
                    <template #right-icon>
                        <!-- <van-icon name="search" class="search-icon" /> -->
                        <van-button plain type="info" size="small" @click="scanCard">刷工作卡</van-button>
                    </template>
                </van-cell>
               <!-- <p >姓名:{{info.dingtalk.nickname}}</p>
                <p >岗位：{{info.dingtalk.station}}</p> -->
            </div>
            <van-divider content-position="left">卡片信息</van-divider>
            <div>
                <div class="info-item" v-if="cardInfo">
                    <van-cell class="my-cell"
                        :title="cardInfo.dingtalk.nickname" 
                        :label="cardInfo.dingtalk.station"
                        value-class="my-value-cls"
                    >
                        <template #right-icon>
                            <!-- <van-icon name="search" class="search-icon" /> -->
                            <van-button plain type="info" size="small" @click="scanCard">切换登录</van-button>
                        </template>
                    </van-cell>
                </div>
                <div class="info-item" v-else>
                    <div class="">{{message}}</div>
                </div>
            </div>
            
           <!-- <van-cell-group>
              <van-cell title="单元格" label="描述信息" />
              <van-cell title="单元格" value="内容" label="描述信息" />
            </van-cell-group> -->
            <!-- <van-button type="info" v-show="info" @click="login">切换登录</van-button> -->
        </div>
    </van-popup>
</template>

<script>
    // 订单明细表单
    import { checkLogin } from '@/utils/common.js';
    import { getUserInfoWithCard, getUser} from '@/api/task.js';
    import { cardLogin} from '@/api/login-with-card.js';
    import { Notify } from 'vant';
    export default{
        data() {
            return{
                show:false,
                cardNo: '',
                message: '',
                info: null,
                cardInfo: null,
            }
        },
        methods:{
            scanCard(){
                var that = this;
                that.message = '请把卡片贴在设备后边。';
                that.$dd.device.nfc.nfcRead({
                  onFail: (err) => {
                      var  {tagId} = err;
                        if(!tagId){
                            Notify({ type: 'warn', message: '未读取到工作证相关信息'});
                        }
                        that.message = '读取成功，正在获取对应员工信息';
                        //var cardNo = '14:be:44:60';
                        that.getCardUser(tagId);
                    }
                });
            },
            close(){
                this.cardNo = '';
                this.message = '';
                this.info = null;
                this.cardInfo = null;
            },
            getUser(){
                checkLogin().then(()=> {
                    getUser().then(({data})=> {
                        this.info = data;
                    })
                })
            },
            getCardUser(cardNo){
                var that = this;
                that.cardNo = cardNo;
                //that.message = '读取成功，正在获取该卡片用户信息';
                var param = {
                    card: cardNo
                }
                getUserInfoWithCard(param).then((ret)=> {
                    console.log(ret);
                    if(ret.data.uid == that.info.uid){
                        return that.message = '工作证关联用户和当前登录用户是同一人，无法切换';
                    }
                    that.cardInfo = ret.data;
                }).catch((err)=> {
                    var msg = err.msg || err.message;
                    // that.message = msg;
                    // if(msg == '该工作证尚未绑定员工'){
                    //     return Dialog.confirm({
                    //        title: '提示',
                    //        message: msg,
                    //        confirmButtonText: '去绑卡',
                    //      }).then(() => {
                    //          that.$router.push('/mes/order/card');
                             
                    //      })
                    // }
                    Notify({ type: 'warning', message: msg});
                    that.message = msg;
                })
            },
            login(){
                var that = this;
                var uid = this.info.uid;
                cardLogin(uid).then(()=>{
                    that.show = false;
                    that.$parent.reboot();
                }).catch((err)=> {
                    Notify({ type: 'warning', message: err.msg || err.message});
                    that.message = err.msg || err.message;
                })
            },
        }
    }
</script>

<style scoped>
    .my-form{
        position: relative;
        /* padding: 30px 20px 10px; */
        font-size: 15px;
        text-align: left;
    }
    .my-form .info-item{
        padding: 0px 30px;
    }
    .my-input{
        /* height: 30px;
        line-height: 30px; */
        padding: 5px 2px;
        border: 1px solid #ccc;
    }
    .footer-box{
        padding-top: 20px;
    }
    .card-info{
        padding: 15px;
        font-size: 14px;
    }
    .my-cell{
        padding: 10px 0;
    }
    /* .label-form1{
        display: block;
        font-size: 13px !important;
        font-weight: bold !important;
        color: #666 !important;
    } */
</style>