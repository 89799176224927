<template>
    <van-popup v-model="show" :style="{ width: '90%',}" @close="close()">
        <van-form @submit="onSubmit" class="my-form">
            <van-cell-group>
              <van-field
                  required
                  label-width="100px"
                  v-model="formData.gin"
                  name="gin"
                  label="GIN编码"
                  clearable
                  :rules="[{ required: true, message: '请填写GIN编码' }]"
                />
            </van-cell-group>
            
            <van-field
              required
              label-width="100px"
              v-model="formData.original_model"
              name="original_model"
              label="柴油机原机型号"
              clearable
              :rules="[{ required: true, message: '请填写柴油机原机型号' }]"
              />
            <van-field
              required
              label-width="100px"
              v-model="formData.body_no"
              name="body_no"
              label="柴油机体钢印号"
              clearable
              :rules="[{ required: true, message: '请填写柴油机体钢印号' }]"
            />
            <van-field
                required
                label-width="100px"
                v-model="formData.motor_power"
                name="motor_power"
                label="电机真实功率"
                clearable
                :rules="[{ required: true, message: '请填写电机真实功率' }]"
              />
            <van-field
                required
                label-width="100px"
                v-model="formData.steal_no"
                name="steal_no"
                label="电机钢印号"
                clearable
                :rules="[{ required: true, message: '请填写电机钢印号' }]"
              />
              <!-- <van-cell-group>
                <span> GIN码</span>
                <van-field class="my-input" v-model="gin" />
                <span> 柴油机原机型号</span>
                <van-field class="my-input" v-model="original_model" />
                <span> 电机真实功率</span>
                <van-field class="my-input" v-model="motor_power" />
                <span> 机体钢印</span>
                <van-field class="my-input" v-model="body_no" />
                <span> 钢印号</span>
                <van-field class="my-input" v-model="steal_no" />
              </van-cell-group> -->
              <div class="footer-box">
                <van-button size="small" block type="info" native-type="submit">确认</van-button>
              </div>
        </van-form>
    </van-popup>
</template>

<script>
    // 订单明细表单
    export default{
        data() {
            return{
                formData:{
                    gin: '',
                    original_model: '',
                    body_no: '',
                    steal_no: '',
                    motor_power: '',
                },
                show:false,
            }
        },
        methods:{
            onSubmit(){
                console.log('submit', this.formData);
                this.show = false;
                this.$parent.confirmBindGin(this.formData);
            },
            close(){
                this.formData = {
                    gin: '',
                    original_model: '',
                    body_no: '',
                    steal_no: '',
                    motor_power: '',
                    show:false,
                };
            },
        }
    }
</script>

<style scoped>
    .my-form{
        position: relative;
        padding: 30px 20px 10px;
        font-size: 15px;
        text-align: left;
    }
    .my-input{
        /* height: 30px;
        line-height: 30px; */
        padding: 5px 2px;
        border: 1px solid #ccc;
    }
    .footer-box{
        padding-top: 20px;
    }
    /* .label-form1{
        display: block;
        font-size: 13px !important;
        font-weight: bold !important;
        color: #666 !important;
    } */
</style>