import * as dd from 'dingtalk-jsapi';
import { baseUrl } from "../utils/config.js";
// import request from './request.js';
import {toast, setTokenToCache } from '../utils/common.js';
import axios from 'axios';

export function cardLogin(uid){
    return new Promise(function(resolve, reject){
        var data = {
            uid: uid
        };
        var url = '/card_login';
        dd.device.notification.showPreloader({
            text:'正在登录...'
        });
        var config = {
            method: 'post', // 默认值
            baseURL: baseUrl,
            responseType: 'json', // 默认值
            data,
            url,
        }
        return axios.request(config).then((res)=>{
            dd.device.notification.hidePreloader();
            toast('登录成功', 'success');
            var {token,  expires_time} = res.data
            // store.dispatch('LOGIN', token);
            setTokenToCache(token, expires_time);
            resolve(token);
        }).catch((err)=>{
            reject(err);
        })
    })
}
